<template>
  <AuditLogTimeline :logs="results" />
</template>

<script>
import { computed, toRef } from "vue-demi";
import { useFind, useGet } from "feathers-pinia";
import { useProcessTemplates } from "../store/processTemplates.pinia";
import { useProcessTemplateFields } from "../store/processTemplateFields.pinia";
import { useAuditLog } from "../store/auditLog.pinia";
import AuditLogTimeline from "./AuditLogTimeline.vue";

export default {
  name: "ProcessAuditLog",
  components: {
    AuditLogTimeline,
  },
  props: {
    processId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const processTemplatesStore = useProcessTemplates();
    const processTemplateFieldsStore = useProcessTemplateFields();
    const auditLogStore = useAuditLog();

    const id = toRef(props, "processId");

    const { item: process } = useGet({
      model: processTemplatesStore.Model,
      id: id,
    });

    const { items: processDetails } = useFind({
      model: processTemplateFieldsStore.Model,
      params: {
        query: {
          process_template_id: id.value,
          $limit: 1000,
        },
      },
    });

    const processDetailIds = computed(() => {
      return processDetails.value?.map((detail) => detail.id) ?? [];
    });

    const auditLogParams = computed(() => {
      return {
        query: {
          entity_type: {
            $in: ["process_template_fields", "process_templates"],
          },
          entity_id: {
            $in: [id.value, ...processDetailIds.value],
          },
          $limit: 1000,
          $sort: { created: -1 },
        },
      };
    });

    const { items: auditLogs } = useFind({
      model: auditLogStore.Model,
      params: auditLogParams,
    });

    const results = computed(() => {
      return [
        ...(auditLogs.value ?? []).map((item) => {
          if (item.entity_type === "process_templates") return item;
          return {
            label: `${
              processTemplateFieldsStore.itemsById[item.entity_id].title
            } -> ${item.field}`,
            ...item,
          };
        }),
        // Created lines for process details
        ...(processDetails?.value?.map((detail) => {
          return {
            id: detail.id,
            created: detail.created,
            created_by_id: detail.created_by_id,
            field_type: "string",
            new_value: `${detail.title} -> Created`,
            single_line: true,
          };
        }) ?? []),
        // Created item for the process
        {
          id: "created",
          created: process.value?.created,
          created_by_id: process.value?.created_by_id,
          field_type: "string",
          new_value: "Created",
          single_line: true,
        },
      ];
    });

    return {
      process,
      auditLogs,
      results,
    };
  },
};
</script>
